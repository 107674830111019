
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo-image {
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
  }
  
  .logo-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
  }
  
  .logo-highlight {
    color: #7d2f9c; /* Purple for the "Yoga" part */
  }
  
  .nav {
    display: flex;
  }
  
  .nav-list {
    display: flex;
    gap: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    font-size: 1rem;
  }
  
  .nav-link {
    text-decoration: none;
    color: #000;
    font-weight: 500;
  }
  
  .nav-link:hover {
    color: #7d2f9c;
  }
  
  .nav-link {
    text-decoration: none;
    color: #000;
  }
  
  .nav-link.active {
    /* text-decoration: underline; */
    color: #7d2f9c !important;
  }
  