.payment-section {
    font-family: "Arial", sans-serif;
    text-align: center;
    padding: 40px;
    background: #f9f3fc;
  }
  
  .payment-header {
    margin-bottom: 30px;
  }
  
  .payment-header h2 {
    color: #7d2d5a;
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  .qr-code-container {
    margin: 20px 0;
  }
  
  .qr-code {
    /* width: 150px; */
    height: 350px;
  }
  
  .contact-message {
    margin-top: 20px;
    font-size: 1.1rem;
    color: #333;
    text-align: center;
    font-weight: bold;
  }
  
  .payment-details {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    height: 270px;
  }
  
  .plan-title {
    font-size: 1.5rem;
    color: #343a40;
    margin-bottom: 10px;
  }
  
  .amount {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .price {
    font-size: 2rem;
    color: #7d2d5a;
  }
  
  .price-subtext {
    font-size: 0.9rem;
    color: #6c757d;
  }
  
  .plan-features {
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .plan-features li {
    font-size: 1rem;
    margin: 5px 0;
  }
  
  /* UPI Instructions Section */
  .upi-instructions {
    margin-top: 20px;
    text-align: center;
  }
  
  .upi-id-container {
    display: inline-block;
    background-color: #f0f0f0;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
    transition: background-color 0.3s ease;
  }
  
  .upi-id-container:hover {
    background-color: #e0e0e0;
  }
  .mt-20{
    margin-top: 20px;
  }
  
  .copied-text {
    position: absolute;
    top: -25px;
    right: 0;
    font-size: 14px;
    color: green;
    font-weight: bold;
  }
  
  .copied {
    background-color: #d4edda;
  }
  