/* Contact Us Section */
.contact-us {
    padding: 3rem 1rem;
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
  }
  
  .contact-us-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .contact-us-title {
    font-size: 3rem;
    font-weight: bold;
    color: #7d2f9c;
    text-transform: uppercase;
    margin-bottom: 1rem;
    animation: fadeIn 1s ease-in-out;
  }
  
  .contact-us-subtitle {
    font-size: 1.4rem;
    color: #333;
    margin-top: 1rem;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Form Section */
  .contact-form {
    margin-top: 2rem;
    animation: slideUp 1s ease-out;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: 80%;
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 0.5rem;
  }
  
  .input-error {
    border-color: #f44336;
  }
  
  .error-message {
    font-size: 0.9rem;
    color: #f44336;
    margin-top: 0.3rem;
  }
  
  .submit-button {
    background-color: #7d2f9c;
    color: white;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 1rem;
    transition: all 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #651a7b;
  }
  
  .submit-button:focus {
    outline: none;
  }
  
  /* Popup Section */
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeInPopup 1s ease-in-out;
  }
  
  .popup-content {
    background-color: white;
    padding: 0 2rem 2rem;
    border-radius: 10px;
    text-align: center;
    width: 80%;
    max-width: 500px;
    animation: scaleUp 0.5s ease-out;
  }
  
  .popup h2 {
    font-size: 2rem;
    color: #7d2f9c;
  }
  
  .popup p {
    font-size: 1.2rem;
    color: #333;
    margin-top: 1rem;
  }
  
  .close-popup {
    background-color: #7d2f9c;
    color: white;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    margin-top: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .close-popup:hover {
    background-color: #651a7b;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeInPopup {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes scaleUp {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1);
    }
  }
  

  /* Container for the toggle button */
.language-toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  /* Style for the actual toggle button */
  .language-toggle {
    position: relative;
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    box-sizing: border-box;
  }
  
  /* Circle inside the toggle */
  .language-toggle:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  /* Style for when the toggle is in "Hindi" mode */
  .language-toggle.active {
    background-color: #7d2f9c; /* Green color */
  }
  
  .language-toggle.active:before {
    transform: translateX(30px); /* Move to the right when active */
  }
  
  /* Button text style */
  .language-toggle-text {
    font-size: 16px;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  /* Specific text for both languages */
  .language-toggle-text span {
    position: absolute;
    width: 50%;
    text-align: center;
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: normal;
    color: #fff;
    pointer-events: none;
  }
  
  .language-toggle-text .english {
    left: 0;
  }
  
  .language-toggle-text .hindi {
    right: 0;
  }
  