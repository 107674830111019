.footer {
  background-color: #8C198C; 
  color: #ecf0f1; 
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
  position: relative;
  overflow: hidden;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
  animation: fadeIn 1s ease-in-out;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  animation: slideIn 1s ease-in-out;
}

.footer-logo .logo {
  max-width: 120px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.footer-logo h3 {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

.footer-logo:hover .logo {
  transform: rotate(10deg);
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
  margin-top: 30px;
}

.footer-column {
  flex: 1;
  min-width: 200px;
  text-align: left;
  animation: fadeInUp 1s ease-in-out;
  margin-bottom: 20px;
}

.footer-column h4 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #ecf0f1;
  font-weight: 500;
  text-transform: uppercase;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
}

.footer-column ul li a {
  color: #bdc3c7;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-column ul li a:hover {
  color: #ecf0f1;
  transform: translateX(5px);
}

.footer-contact {
  flex: 1;
  min-width: 200px;
  text-align: left;
  animation: fadeInUp 1s ease-in-out;
  margin-bottom: 20px;
}

.footer-contact h4 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #ecf0f1;
  font-weight: 500;
}

.footer-contact p {
  font-size: 16px;
}

.footer-contact a {
  color: #ecf0f1;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer-contact a:hover {
  color: #f39c12; /* Highlight color */
}

.social-icons {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.social-icons a img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-icons a:hover img {
  transform: scale(1.2);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

