.about-us {
    padding: 3rem 1rem;
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
  }
  
  .about-us-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .about-us-title {
    font-size: 3.5rem;
    font-weight: bold;
    color: #7d2f9c;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 1rem;
  }
  
  .about-us-subtitle {
    font-size: 1.4rem;
    color: #333;
    margin-top: 1rem;
  }
  
  .about-us-description {
    font-size: 1.2rem;
    color: #555;
    margin-top: 1.5rem;
    line-height: 1.6;
  }
  
  .teacher-info h2 {
    font-size: 2.5rem;
    color: #7d2f9c;
    margin-top: 2rem;
  }
  
  .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin: 1rem 0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .card-body {
    padding: 2rem;
    font-size: 1.2rem;
    color: #555;
  }
  
  .teacher-image {
    border-radius: 110px;
    object-fit: cover;
    padding-top: 20px;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .card-container .card {
    width: 250px;
    text-align: center;
    padding: 1.5rem;
  }
  
  .card-container .card h3 {
    font-size: 1.5rem;
    color: #7d2f9c;
  }
  
  .yoga-benefits-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-join {
    margin-top: 3rem;
  }
  
  .about-us-join h2 {
    font-size: 2.5rem;
    color: #7d2f9c;
    margin-bottom: 1rem;
  }
  
  .about-us-join p {
    font-size: 1.2rem;
    color: #555;
    margin-top: 1.5rem;
    line-height: 1.8;
  }
  
  @media (max-width: 768px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }
    .card-container .card {
      width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .about-us-title {
      font-size: 2.5rem;
    }
  
    .about-us-subtitle {
      font-size: 1.2rem;
    }
  
    .card-container .card {
      width: 80%;
    }
  }
  


  .contact-info {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 2rem auto;
    text-align: center;
  }
  
  .contact-title {
    font-size: 1.8rem;
    color: #7d2f9c;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .contact-details {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.8;
  }
  
  .contact-details span {
    display: block;
    margin: 10px 0;
  }
  
  .contact-link {
    color: #7d2f9c;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .contact-link:hover {
    color: #651a7b; /* Darker shade for hover effect */
  }
  
  .contact-link:focus {
    outline: none;
    border-bottom: 2px solid #7d2f9c;
  }
  

  .founder-info {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
  
  .founder-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 48%;
  }
  
  .founder-card h3 {
    font-size: 1.5rem;
    color: #7d2f9c;
    margin-bottom: 10px;
  }
  
  .role {
    font-style: italic;
    color: #777;
    margin-bottom: 10px;
  }
  
  .founder-card p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .founder-info {
      flex-direction: column;
      align-items: center;
    }
  
    .founder-card {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  

  .mt-20{
    padding: 20px;
  }