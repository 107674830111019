.testimonials-section {
  background: #f9f9f9;
  padding: 0px 20px 20px;
  text-align: center;
  font-family: "Arial", sans-serif;
  position: relative;
}



.section-title {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #343a40;
}

.section-title .highlight {
  color: #9e2a83;
}

.testimonials-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  max-width: 100%;
}

.carousel-inner {
  display: flex;
  width: 400%; /* 100% for each card */
  transition: transform 0.5s ease-out;
}

.testimonial-card {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  flex: 0 0 auto;
  margin: 0 10px;
  opacity: 0.7;
  transform: scale(0.95);
  transition: all 0.3s ease-out;
}

.testimonial-card.active {
  opacity: 1;
  transform: scale(1);
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto 15px;
  display: block;
}

.testimonial-content {
  text-align: center;
}

.testimonial-name {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonial-batch {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 15px;
}

.testimonial-review {
  font-size:12px;
  /* padding: 0 40px; */
  line-height: 1.6;
  color: #495057;
}

.dots-container {
  margin-top: 20px;
}

.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #ccc;
  border-radius: 50%;
  margin: 0 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.dot.active {
  background: #9e2a83;
}

@media (max-width: 768px) {
  .carousel-inner {
    width: calc(100% * 4);
  }

  .testimonial-card {
    width: 250px;
  }

  .testimonial-image {
    width: 70px;
    height: 70px;
  }

  .testimonial-name {
    font-size: 1.1rem;
  }

  .testimonial-batch,
  .testimonial-review {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.8rem;
  }

  .testimonial-card {
    width: 90%;
  }

  .testimonial-name {
    font-size: 1rem;
  }

  .testimonial-batch,
  .testimonial-review {
    font-size: 0.85rem;
  }
}
