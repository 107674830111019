/* Import the 'Poppins' font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  color: #333;
}

.landing-page {
  line-height: 1.6;
  padding: 0 20px;
}

.why-us-sec {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; 
  gap: 20px;
}

.widthfix {
  max-width: 700px !important;
  text-align: justify;
  font-weight: 500;
  font-size: 20px !important;
}

.why-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.certification-badge {
  max-width: 150px; 
  margin-bottom: 20px;
}

.stats {
  display: flex;
  justify-content: space-around;
  text-align: center;
  gap: 20px; 
  flex-wrap: wrap;
}

.stats div {
  flex: 1;
  min-width: 120px;
}
.themecolour{
  color: #7d2f9c;
}
.stats h3 {
  color: #7d2f9c;
  font-size: 40px; 
  font-weight: 700;
  margin: 0;
  transition: all 0.5s ease-in-out;
}
.borderhere{
  border: 2px solid #7d2f9c;
  border-radius: 10px;
  padding: 20px;
}
.stats p {
  font-size: 16px;
  color: #555;
}
.themecolour{
  color: #7d2f9c !important;
}
.why-yoga {
  background-color: #7d2f9c14;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.why-yoga h2 {
  color: #1e88e5;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 28px;
}

.why-yoga p {
  text-align: justify;
  margin-bottom: 20px;
  font-size: 16px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center; 
}

.illustration img {
  max-width: 100%;
  height: auto;
  margin: 0 auto; 
}

.philosophy {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.philosophy h2 {
  color: #7d2f9c;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 28px;
}

.philosophy p {
  margin-bottom: 10px;
  text-align: justify;
  font-size: 16px;
}

.group-photo-image {
  max-width: 100%;
  border-radius: 8px;
  height: auto;
  margin: 0 auto; 
}

@media (max-width: 768px) {
  .why-us-sec,
  .flex-row {
      flex-direction: column; 
  }

  .certification-badge {
      max-width: 120px; 
  }

  .stats h3 {
      font-size: 32px; 
  }

  .philosophy h2,
  .why-yoga h2 {
      font-size: 24px;
  }

  .stats p,
  .philosophy p,
  .why-yoga p {
      font-size: 14px;
  }
}

@media (max-width: 480px) {
  .numericsection {
      padding: 20px;
      font-size: 14px; 
  }

  .stats div {
      min-width: 100px; 
  }

  .certification {
      margin-bottom: 15px; 
  }

  .philosophy h2,
  .why-yoga h2 {
      font-size: 20px;
  }

  .philosophy p,
  .why-yoga p {
      font-size: 12px;
  }
}
