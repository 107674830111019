/* Container Styling */
.pricing-section {
    background: linear-gradient(to right, #fdeff3, #f9d6f5);
    padding: 40px;
    text-align: center;
    font-family: 'Arial', sans-serif;
}

.pricing-title {
    color: #7d2d5a;
    font-size: 2rem;
    margin-bottom: 20px;
}

/* Card Container */
.pricing-container {
    display: flex;
    justify-content: center; /* Align cards to the center */
    flex-wrap: wrap;
    gap: 20px;
}

/* Individual Card Styling */
.pricing-card, .demo-card, .coming-soon-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 280px;
    padding: 20px;
    text-align: left; /* Align text to the center */
    position: relative;
}

.highlighted-card {
  border: 3px solid #7d2d5a; /* Thicker border for selected cards */
  background-color: #fbe4f0; /* Light pink background */
  transform: scale(1.05); /* Slightly enlarge the card */
  transition: all 0.3s ease-in-out;
}


/* Text and Button Styling */
.plan-title {
    font-size: 1.2rem;
    font-weight: bold;
}

.price {
    font-size: 2rem;
    color: #7d2d5a;
    margin: 10px 0;
}

.price-subtext {
    font-size: 0.8rem;
    color: #666;
}

.get-started-btn, .demo-btn {
    background: #7d2d5a;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.get-started-btn:hover, .demo-btn:hover {
    background: #8C198C;
}

/* Features List */
.plan-features {
    list-style: none;
    padding: 0;
    margin: 10px 0;
    text-align: left; /* Align features to the left for readability */
}

.plan-features li {
    margin: 5px 0;
    float: left;
}

/* Demo Section */
.demo-card {
    background: #f9d6f5;
    color: #7d2d5a;
}

.demo-card h3 {
    font-size: 1.5rem;
}

.demo-btn {
    background: #7d2d5a;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

/* Coming Soon */
.coming-soon-card {
    background: #f9d6f5;
    color: #7d2d5a;
    text-align: center;
    font-size: 1.2rem;
    padding: 40px 20px;
}

/* Adjustments for Smaller Screens */
@media (max-width: 768px) {
    .pricing-container {
        flex-direction: column; /* Stack cards vertically on small screens */
        align-items: center; /* Center align vertically stacked cards */
    }

    .pricing-card, .demo-card, .coming-soon-card {
        width: 90%; /* Make cards responsive */
    }

    .price {
        font-size: 1.5rem; /* Adjust font size for smaller screens */
    }

    .plan-title {
        font-size: 1.1rem;
    }

    .plan-features {
        text-align: center; /* Center-align features on smaller screens */
    }
}




.pricing-section {
  background: linear-gradient(to right, #fdeff3, #f9d6f5);
  padding: 40px;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.pricing-title {
  color: #7d2d5a;
  font-size: 2rem;
  margin-bottom: 20px;
}

.pricing-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.pricing-card, .demo-card, .coming-soon-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 280px;
  padding: 20px;
  text-align: left;
  position: relative;
}

.pricing-card.highlighted-card {
  border: 3px solid #7d2d5a;
  background-color: #fbe4f0;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.plan-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.price {
  font-size: 2rem;
  color: #7d2d5a;
  margin: 10px 0;
}

.price-subtext {
  font-size: 0.8rem;
  color: #666;
}

.get-started-btn, .demo-btn {
  background: #7d2d5a;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.get-started-btn:hover, .demo-btn:hover {
  background: #8C198C;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  text-align: left;
}

.plan-features li {
  margin: 5px 0;
  float: left;
}

.demo-card {
  background: #f9d6f5;
  color: #7d2d5a;
}

.demo-card h3 {
  font-size: 1.5rem;
}

.demo-btn {
  background: #7d2d5a;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.coming-soon-card {
  background: #f9d6f5;
  color: #7d2d5a;
  text-align: center;
  font-size: 1.2rem;
  padding: 40px 20px;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.popup-content h3 {
  font-size: 1.5rem;
  color: #7d2d5a;
  margin-bottom: 20px;
}

.popup-content form {
  display: flex;
  flex-direction: column;
}

.popup-content label {
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 13px;
}

.popup-content select, .popup-content input {
  padding-left: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 45px;
}

.popup-content select:focus, .popup-content input:focus {
  border-color: #7d2d5a;
}

.popup-content .error {
  color: red;
  font-size: 0.8rem;
  margin-top: -5px;
  margin-bottom: 10px;
}

.book-call-btn {
  background: #7d2d5a;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.book-call-btn:hover {
  background: #8C198C;
}

@media (max-width: 768px) {
  .pricing-container {
      flex-direction: column;
      align-items: center;
  }

  .pricing-card, .demo-card, .coming-soon-card {
      width: 90%;
  }

  .price {
      font-size: 1.5rem;
  }

  .plan-title {
      font-size: 1.1rem;
  }

  .plan-features {
      text-align: center;
  }
}
