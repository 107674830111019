/* Theme Colors */
:root {
    --primary-color: #f8c8d8;  /* Light Pink */
    --secondary-color: #ffffff; /* White */
    --accent-color: #f1a7c1;    /* Accent Pink */
    --text-color: #333333;      /* Dark Text */
  }
  
  body {
    background-color: var(--secondary-color);
    font-family: 'Arial', sans-serif;
    color: var(--text-color);
  }
  
  .schedule-container {
    width: 90%;
    max-width: 800px;
    margin: 50px auto;
    background-color: var(--secondary-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  /* Heading Styles */
  .schedule-heading {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  /* Day Schedule */
  .day-schedule {
    margin-bottom: 30px;
  }
  
  .day-name {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  /* Shift Styles */
  .shifts {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .shift {
    background-color: var(--accent-color);
    padding: 20px;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .shift:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .shift-name {
    font-size: 1.8rem;
    color: var(--secondary-color);
    margin-bottom: 10px;
  }
  
  .shift-time, .shift-instructor {
    font-size: 1.2rem;
    color: var(--secondary-color);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .schedule-heading {
      font-size: 2rem;
    }
  
    .day-name {
      font-size: 1.7rem;
    }
  
    .shift {
      padding: 15px;
    }
  
    .shift-name {
      font-size: 1.5rem;
    }
  
    .shift-time, .shift-instructor {
      font-size: 1rem;
    }
  }
  