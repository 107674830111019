.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: #fdfdfd;
  flex-wrap: wrap;
}

.hero-content {
  max-width: 50%;
  text-align: left;
}

.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 0.5rem;
}
.flex{
  display: flex;
}

.hero-highlight {
  color: #7d2f9c !important;
}

.hero-subtitle {
  font-size: 1.2rem;
  color: #333;
  margin-top: 0.5rem;
}

.hero-form {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  gap: 10px;
}

.hero-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  flex-grow: 1;
  min-width: 320px;
  height: 60px;
}

.input-prefix {
  background-color: #f3f3f3;
  padding: 1.5rem 1rem;
  font-size: 1rem;
  color: #333;
}

.hero-input {
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  flex-grow: 1;
  outline: none;
}

.hero-button {
  padding: 0.7rem 1.5rem;
  background-color: #7d2f9c;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hero-button:hover {
  background-color: #651a7b;
}

.hero-image {
  max-width: 50%;
  text-align: center;
}

.hero-image-content {
  width: 70%;
  height: auto;
}

@media (max-width: 768px) {
  .hero {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 1.5rem 1.5rem 0;
  }

  .hero-content {
      max-width: 100%;
      margin-bottom: 1rem;
  }

  .hero-title {
      font-size: 2rem;
  }
.text-align-center{
  text-align: center;
}
  .hero-title-one{
    display: flex
;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hero-subtitle {
      font-size: 1rem;
  }

  .hero-form {
      flex-direction: column;
      width: 100%;
      gap: 15px;
  }

  .hero-input-wrapper {
      max-width: 100%;
  }

  .hero-button {
    width: 100%;
    height: 60px;
    max-width: 220px;
  }

  .hero-image {
      max-width: 100%;
      /* margin-top: 1rem; */
  }
}

@media (max-width: 480px) {
  .hero-title {
      font-size: 38px;
  }

  .hero-subtitle {
      font-size: 20px;
  }

  .hero-button {
    width: 100%;
    height: 60px;
    max-width: 220px;
  }
}





.hero-button:disabled {
  background-color: #dcdcdc; /* Disabled state */
  color: #888;
}

.popup-content {
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.popup-image {
  width: 100px;
  height: 100px;
  margin: 20px 0;
}

.close-popup {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-popup:hover {
  background-color: #45a049;
}

.hero-subtitle {
  position: relative;

  font-size: 24px;
  font-weight: 600;
  text-align: center;
  height: 33px; 
  overflow: hidden; 
}

.scrolling-text {
  position: absolute;
  width: 100%; /* Ensure it covers the full width of the parent container */
  opacity: 0;
  animation: scrollUp 9s linear infinite;
  font-size: 24px; /* Same font size as the container */
}

.scrolling-text:nth-child(1) {
  animation-delay: 0s;
}

.scrolling-text:nth-child(2) {
  animation-delay: 3s; /* Adjust the timing to control when Health appears */
}

.scrolling-text:nth-child(3) {
  animation-delay: 6s; /* Adjust the timing to control when Calmness appears */
}

@keyframes scrollUp {
  0% {
    top: 100%;
    opacity: 1;
  }
  33% {
    top: 0%;
    opacity: 1;
  }
  66% {
    top: -100%;
    opacity: 0;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}



.popup-content {
  text-align: center;
}

.popup-form {
  margin: 20px 0;
}

.popup-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.xbg{
  background: #8080803d;
  padding: 5px 9px;
  border-radius: 23px;
}
.popup-form input,
.popup-form select {
  width: 100%;
  height: 50px;
  padding-left: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.submit-popup {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-popup:hover {
  background-color: #218838;
}

