.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .blog-title {
    font-size: 36px;
    color: #7d2f9c; /* Your theme color */
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .blog-intro {
    font-size: 18px;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .image-section {
    text-align: center;
  }
  
  .blog-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .section-heading {
    font-size: 28px;
    color: #7d2f9c; /* Your theme color */
    font-weight: bold;
    margin-top: 40px;
  }
  
  .section-description, .teacher-description {
    font-size: 18px;
    margin-top: 15px;
    line-height: 1.6;
  }
  
  .benefits-list {
    list-style: none;
    margin-top: 20px;
    padding-left: 0;
  }
  
  .benefits-list li {
    font-size: 18px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .benefits-list li::before {
    content: "✔️";
    position: absolute;
    left: -25px;
    color: #7d2f9c;
  }
  
  .teacher-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .teacher-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .teacher-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .call-to-action {
    text-align: center;
    margin-top: 50px;
  }
  
  .cta-heading {
    font-size: 24px;
    color: #7d2f9c;
    font-weight: bold;
  }
  
  .cta-description {
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.6;
  }
  
  .cta-button {
    background-color: #7d2f9c;
    color: white;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #7d2f9c;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .blog-title {
      font-size: 30px;
    }
  
    .section-heading {
      font-size: 24px;
    }
  
    .teacher-image {
      width: 120px;
      height: 120px;
    }
  
    .cta-button {
      padding: 10px 20px;
    }
  }
  